import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  fonts: {
    heading: `"Eina 01",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"`,
    body: `"Eina 01",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"`,
  },
  colors: {
    brand: {
      100: "#D0F0F2", // Likely wrong, added for badge

      400: "#56C3CF",
      500: "#13B5C4",

      600: "#10a0ae", // Selected by Charles

      800: "#13B5C4", // Likely wrong, added for badge
    },
  },
  textStyles: {
    title: {
      fontSize: "40px",
      fontWeight: "bold",
      lineHeight: "110%",
      letterSpacing: "-2%",
    },
    h2: {
      fontSize: ["36px", "48px"],
      fontWeight: "semibold",
      lineHeight: "110%",
      letterSpacing: "-1%",
    },
  },
  components: {
    Link: {
      baseStyle: {
        color: "brand.500",
      },
    },
  },
});

export const lightTextOpacity = "75%";
export const lightestTextOpacity = "50%";

/* Breakpoints

sm 480
md 768
lg 992
xl 1280
2xl 1536
*/

// Uncomment to get breakpoint values
// Object.entries(theme.breakpoints).map(([breakpoint, width]) =>
//   console.log(breakpoint, +(width as string).replace("em", "") * 16)
// );
